import * as React from 'react';
import type { Rule } from '@mentimeter/ragnar-styled';
import {
  styled,
  LAYOUT,
  POSITION,
  FLEX_CONTAINER,
} from '@mentimeter/ragnar-styled';
import toArray from 'lodash/toArray';
import { combineRules } from '@mentimeter/ragnar-react';
import { addUnit } from '@mentimeter/ragnar-utils';
import { rule as boxRule } from '../Box';
import { ScreenReaderOnly } from '../ScreenreaderOnly/ScreenReaderOnly';

export interface CharacterCountT {
  value: string;
  maxLength: number;
  id?: string | undefined;
  className?: string | undefined;
  multiline?: boolean | undefined;
  iconTrailing?: boolean | undefined;
}

const wrapperRule = ({ multiline = false }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: multiline ? 'flex-end' : 'center',
});

const characteCountRule: Rule<CharacterCountT> = ({
  theme,
  value,
  maxLength,
  multiline = false,
  iconTrailing,
}) => {
  const charactersTyped = value?.length;
  const percentage = charactersTyped / maxLength;
  return {
    lineHeight: 1,
    fontSize: addUnit(theme.fontSizes[0]),
    fontFamily: theme.fonts.body,
    fontWeight: theme.fontWeights.semiBold,
    color: percentage > 0.5 ? theme.colors.negative : theme.colors.text,
    transition: `color ${theme.durations[1]}s ease`,
    position: 'absolute',
    right: 0,
    paddingRight: iconTrailing ? '35px' : `${theme.space[2]}px`,
    paddingBottom: multiline ? `${theme.space[2]}px` : undefined,
    pointerEvents: 'none',
  };
};

export const stringToCharacterCount = (value: string) => {
  return toArray(value).length;
};

const CharacterCountC = ({
  value = '',
  maxLength,
  className,
  id,
  multiline,
  iconTrailing,
}: CharacterCountT) => {
  const charactersTyped = stringToCharacterCount(value);
  const count = maxLength - charactersTyped;
  return (
    <span className={className} id={id} aria-live="polite" aria-atomic="true">
      {count < 0 ? 0 : count}
      <ScreenReaderOnly> characters left</ScreenReaderOnly>
    </span>
  );
};

export const CharacterCountWrap = styled('div', {
  displayName: 'CharacterCountWrap',
})(
  wrapperRule,
  ...LAYOUT,
  ...FLEX_CONTAINER,
  ...POSITION,
  'color',
  'kosmosSpacing',
);

export const CharacterCount = styled(CharacterCountC, {
  displayName: 'CharacterCount',
})(combineRules(characteCountRule, boxRule), 'kosmosSpacing');
